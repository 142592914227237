.side-image-container{
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 188px);
    border-radius: 16px;
    background-color: #FBFBFF;
    border: 1px solid var(--border-color, #E5E7EC)
}
.messaging-form-container{
    overflow-y: auto;
    height: calc(100vh - 170px);
}

.messaging-form-container::-webkit-scrollbar {
    display: none;
}

.quill-editor{
     height:calc(100vh - 460px);
     margin-bottom: 52px;
}

.send-button{
    background-color: #ED7047 !important;
    text-transform: capitalize !important;
    padding: 6px 50px !important;
}

.client-messaging {
  // Autocomplete styles
 

  .MuiAutocomplete-inputRoot{
       span{
         height: auto;
         font-size: 14px;
         color: #16304FCC;
       }
  }
  .MuiAutocomplete-root {
    .MuiOutlinedInput-root {
      padding: 8px !important;
    }
  }

  // Selected chips styles
  .MuiAutocomplete-tag {
    background-color: #f0f7ff !important;
    border: 1px solid #e0e0e0;
    border-radius: 16px !important;
    margin: 3px;
    padding: 4px 8px;
    height: 32px;

    .MuiChip-label {
      color: #16304FCC;
      font-size: 14px;
      padding: 0 8px;
    }

    .MuiChip-deleteIcon {
      color: var(--primary-red-color, #EA3E23);
;
      margin-right: 4px;
      font-size: 18px;

      &:hover {
        color: #1565c0;
      }
    }
  }

  // Dropdown option styles
  .MuiAutocomplete-option {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #e0e0e0;
    justify-content: space-between;

    &:hover {
      background-color: #f5f5f5;
    }

    .custom-checkbox-large {
      display: flex;
      align-items: center;
      margin-right: 10px;

      input[type="checkbox"] {
        display: none; // Hide the default checkbox
      }

      .checkmark-large {
        width: 20px;
        height: 20px;
        border: 1px solid #ccc;
        border-radius: 4px;
        position: relative;
        cursor: pointer;

        &::after {
          content: '';
          position: absolute;
          left: 4px;
          top: 8px;
          width: 5px;
          height: 10px;
          border: solid #1976d2;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
          opacity: 0; // Initially hidden
        }
      }

      input[type="checkbox"]:checked + .checkmark-large::after {
        opacity: 1; // Show checkmark when checked
      }
    }

    // Style for the email display
    span {
      margin-left: auto; // Push email to the right
      color: #888;
      font-size: 0.9rem; // Slightly smaller font size
    }
  }

  .MuiChip-root {
    background-color: #e0f7fa;
    color: #00796b;
    margin: 2px;
  }
}

.client-selection-option{
    height: 50px;
    border-bottom: 1px solid var(--border-color, #E5E7EC);
    .custom-checkbox-large{
        margin-right: 10px;
    }
    .client-selection-option-label{
        width: 280px;
        font-size: 14px;
        font-weight: 400;
        color: #637381;
    }
    .client-selection-option-email{
        color: #637381;
         font-size: 14px;
        font-weight: 400;
    }
}
.odd-row{
    background-color: #FBFBFF;
}